/* ==========================================================================

                        H E A D E R

========================================================================== */
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "language";
@import "../product/originalAndLowestPrice";
@import "../_settings/globalSetting";

:root {
    --header-height: 64px; /* default value before getting value using JS */
    --announcement-bar-height: 32px; /* default value before getting value using JS */
}

.cc-header {
    @include animation();
    background-color: $white;
    position: sticky;
    top: 0;
    z-index: 5;
    border-bottom: rem-calc(1) solid $warm-grey-02;
    transform: unset;

    @include media-breakpoint-down(sm) {
        @include animationSlow();
    }

    [data-action="Search-Show"] & {
        position: relative;
    }

    &__tag {
        &__scrollDown {
            transform: translateY(-114px);
        }

        &__viewHeader.cc-header {
            position: sticky;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    .cc-top-header {
        background: $white;
        line-height: rem-calc(17);
        padding: rem-calc(10) 0;

        @include media-breakpoint-down(sm) {
            display: none;
        }

        .cc-customer-care-text {
            color: $black;
            display: block;
            font-size: rem-calc(12);
            font-weight: $primary-font-medium;
            padding: rem-calc(2) 0 rem-calc(3);
            text-align: left;

            a,
            .cc-text-link {
                color: $black;
                font-weight: $primary-font-medium;
                text-decoration: none;
            }
        }

        .cc-top-header-navs {
            .cc-content-items {
                display: block;
                font-size: 0;
                text-align: right;

                .cc-item {
                    color: $black;
                    display: inline-block;
                    font-size: rem-calc(12);
                    font-weight: $primary-font-medium;
                    margin-left: rem-calc(30);
                    vertical-align: middle;

                    .cc-item-link {
                        cursor: pointer;
                        display: inline-block;
                    }

                    .cc-icon {
                        height: rem-calc(21);
                        margin-right: rem-calc(10);

                        &::before {
                            font-size: rem-calc(21);
                        }
                    }

                    .cc-icon-locator-small {
                        height: rem-calc(21);
                        width: rem-calc(14.85);
                        margin-right: rem-calc(10);
                        display: inline-block;
                    }

                    .cc-icon-text {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .iconClassCommon {
        transition: unset;
    }

    &-main {
        position: relative;

        .cc-main-menu {
            position: relative;
            text-align: center;

            @include media-breakpoint-down(md) {
                position: unset;
                text-align: left;

                .cc-main-wrapper {
                    background: none;
                    position: unset;
                }
            }

            .cc-container-full {
                @include media-breakpoint-down(md) {
                    background-color: rgba(0, 0, 0, .3);
                    overflow-y: hidden;
                    padding: 0;
                }

                @include media-breakpoint-up(lg) {
                    border-bottom: rem-calc(1) solid $hr-border-color;
                    max-height: calc(100vh - 127px);
                    max-width: none;
                    overflow-y: auto;
                }
            }

            .cc-menu-bar-container {
                @include media-breakpoint-up (lg) {
                    display: inline-block;
                    padding-right: rem-calc(201);
                    text-align: left;
                    vertical-align: middle;
                }

                @include media-breakpoint-down(md) {
                    .cc-content-main-search {
                        display: none;
                    }
                }
            }

            @include media-breakpoint-up (lg) {
                .cc-content-menu {
                    text-align: center;

                    .cc-menu-items {
                        font-size: 0;
                        text-align: center;

                        .cc-menu-item {
                            display: inline-block;
                            vertical-align: middle;

                            &.cc-menu-item-1 {
                                margin: 0 rem-calc(24) 0 0;
                                padding: 0;

                                &:last-child {
                                    margin-right: 0;
                                }

                                .cc-main-navigation-link {
                                    &.cc-highlight {
                                        color: $red;
                                    }

                                    .cc-icon {
                                        height: rem-calc(21);
                                        margin-right: rem-calc(10);

                                        &::before {
                                            font-size: rem-calc(21);
                                        }
                                    }

                                    .cc-icon-text {
                                        display: inline-block;
                                        vertical-align: middle;
                                    }
                                }

                                &.cc-menu-item-parent {
                                    &:hover {
                                        .cc-content-items-2 {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            .cc-content-items-2 {
                                background: $white;
                                border-top: rem-calc(1) solid $hr-border-color;
                                display: none;
                                left: 0;
                                max-height: calc(100vh - 61px);
                                overflow-y: auto;
                                padding: rem-calc(30) 0;
                                position: absolute;
                                top: calc(100% - 1px);
                                width: 100%;
                                z-index: 9;

                                .cc-megamenu-container {
                                    margin: 0 auto;
                                    max-width: rem-calc(1425);
                                    width: 90%;
                                }

                                .cc-menu-2-col {
                                    display: inline-block;
                                    text-align: left;
                                    vertical-align: top;
                                    width: 20%;

                                    .cc-menu-item-2 {
                                        width: 100%;
                                    }

                                    .cc-menu-item {
                                        vertical-align: top;

                                        &.cc-menu-item-2 {
                                            padding-top: rem-calc(30);

                                            &:first-child {
                                                padding-top: 0;
                                            }
                                        }
                                    }

                                    .cc-image-link {
                                        display: block;
                                        margin-top: rem-calc(20);
                                        padding-right: rem-calc(40);

                                        .cc-image-link-img {
                                            display: block;
                                            margin: 0;
                                            width: 100%;
                                            height: auto;
                                        }

                                        img {
                                            width: 100%;
                                            height: auto;
                                        }
                                    }

                                    .cc-no-border-animation {
                                        .cc-menu-item-link {
                                            border: 0;
                                        }
                                    }

                                    .cc-category-content-img {
                                        display: block;
                                        margin-bottom: rem-calc(10);
                                        padding: 0 rem-calc(20);

                                        .cc-img {
                                            display: block;
                                            margin: 0;
                                            width: 100%;
                                        }
                                    }

                                    .cc-category-text {
                                        display: block;
                                        text-align: center;
                                    }
                                }

                                .cc-menu-item-2 {
                                    text-align: left;
                                    width: 20%;

                                    .cc-show-all-mobile {
                                        display: block;
                                        width: 100%;
                                    }

                                    .cc-main-navigation-link-2 {
                                        color: black;
                                        font-size: rem-calc(14);
                                        font-weight: $primary-font-semibold;
                                        text-transform: uppercase;

                                        &.cc-highlight {
                                            color: $red;
                                        }
                                    }

                                    &.cc-menu-item-parent {
                                        &:hover {
                                            .cc-content-items-2 {
                                                display: block;
                                            }
                                        }
                                    }
                                }

                                .cc-content-items-3 {
                                    display: flex;

                                    .cc-content-items-3-split {
                                        margin-right: rem-calc(24);
                                        width: 50%;
                                    }

                                    .cc-menu-item-3 {
                                        text-align: left;
                                        width: 100%;

                                        .cc-menu-item-link {
                                            font-size: rem-calc(13);

                                            &.cc-highlight {
                                                color: $red;
                                            }

                                            &.cc-show-all {
                                                font-weight: $primary-font-semibold;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cc-content-main-search {
                @include animation;
                background: $white;
                float: right;
                margin-bottom: rem-calc(-50);
                position: relative;
                right: rem-calc(-201);
                top: rem-calc(-50);
                width: rem-calc(183);
                z-index: 4;

                .cc-content-search {
                    padding-bottom: 0;

                    .cc-content-input {
                        float: left;
                        width: calc(100% - 63px);

                        .cc-input {
                            @include animation;
                            border: 0;
                            border-bottom: rem-calc(1) solid $black;
                            border-radius: 0;
                            margin-bottom: 0;
                            padding: rem-calc(5) 0 rem-calc(4);

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        .cc-suggestions-wrapper {
                            overflow-x: hidden;
                            overflow-y: auto;
                            width: calc(100% + 63px);
                        }
                    }

                    .cc-icon {
                        cursor: pointer;
                        display: block;
                        float: right;
                        font-size: rem-calc(21);
                        margin-left: rem-calc(12);
                        margin-top: rem-calc(6);

                        &.cc-icon-close-search {
                            visibility: hidden;
                            height: rem-calc(21);
                            width: rem-calc(21);
                        }

                        &.cc-icon-search {
                            margin-left: 0;
                            height: rem-calc(22);
                            width: rem-calc(22);
                        }
                    }
                }

                &.cc-open {
                    width: calc(100% + 201px);

                    .cc-content-search {
                        padding-left: rem-calc(63);

                        .cc-content-input {
                            .cc-input {
                                text-align: center;
                            }
                        }

                        .cc-icon {
                            &.cc-icon-close-search {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }

        .cc-content-search-mobile {
            overflow: hidden;
            width: 100%;
            background-color: $white;
            color: $black;

            .cc-content-search,
            .cc-content-input,
            form {
                height: 100%;
            }

            &:not(.cc-everOpen) {
                transition: all 0.4s cubic-bezier(0, 0, 0, 1);
                bottom: 0;
                height: 100%;
                left: 0;
                max-height: 100%;
                overflow-y: hidden;
                position: fixed;
                top: 0;
                width: 100%;
                z-index: 9;
                pointer-events: none;
                background: none;

                @include media-breakpoint-down (xs) {
                    background: none;
                    backdrop-filter: none;
                    transform: translateX(-100%);
                    opacity: 1;
                }

                &.cc-open {
                    transition: all 0.4s cubic-bezier(0, 0, 0, 1);
                    left: 0;
                    z-index: 20;
                    pointer-events: initial;
                    backdrop-filter: blur(#{rem-calc(10)});
                    background: rgba($black, 0.2);

                    @include media-breakpoint-down (xs) {
                        background: none;
                        transform: translateX(0%);
                        backdrop-filter: none;
                    }

                    .cc-content-search {
                        @include media-breakpoint-up (sm) {
                            left: 50%;
                        }
                    }
                }
            }

            &.cc-everOpen {
                display: block;
                z-index: 20;

                @include media-breakpoint-up (sm) {
                    .cc-content-search {
                        border-right: 0;
                        max-width: none;
                    }
                }
            }

            .cc-content-search {
                transition: all 0.4s cubic-bezier(0, 0, 0, 1);
                padding: 0;
                position: relative;
                background-color: $white;
                height: 100%;

                @include media-breakpoint-up (sm) {
                    width: 50%;
                    left: 100%;
                }

                .cc-icon-search-button {
                    display: block;
                }

                .cc-content-input {
                    margin: 0;

                    .cc-input-wrp {
                        padding: rem-calc(16) rem-calc(56) rem-calc(16) rem-calc(16);
                        position: relative;

                        @include media-breakpoint-up(lg) {
                            padding: rem-calc(48) rem-calc(72) rem-calc(24) rem-calc(24);
                        }
                    }

                    .cc-input {
                        border-left: 0;
                        border-radius: 0;
                        border-right: 0;
                        border-top: 0;
                        margin: 0;
                        padding: rem-calc(4) rem-calc(160) rem-calc(4) rem-calc(40);
                        background: $warm-grey-01;
                        width: 100%;
                        height: rem-calc(30);
                        border: none;
                        color: $black;

                        &:focus,
                        &:hover {
                            border-color: $grey2;
                            box-shadow: none;
                        }

                        &::placeholder {
                            opacity: 1;
                            color: $black;
                            transition: all .15s ease-in;
                        }
                    }
                }

                .cc-icon-search-button {
                    position: absolute;
                    top: rem-calc(20);
                    left: rem-calc(26);

                    @include media-breakpoint-up(lg) {
                        top: rem-calc(52);
                        left: rem-calc(34);
                    }
                }

                .cc-icon-close-search-button {
                    position: absolute;
                    top: rem-calc(20);
                    right: rem-calc(16);

                    @include media-breakpoint-up(lg) {
                        top: rem-calc(51);
                        right: rem-calc(24);
                    }
                }

                .cc-input {
                    min-height: rem-calc(32);
                    height: rem-calc(32);
                }

                .cc-icon {
                    @include animation;
                    display: block;
                    width: rem-calc(24);
                    height: rem-calc(24);
                    padding: rem-calc(2);
                    color: $black;
                }

                .cc-suggestions-wrapper {
                    height: calc(100% - #{rem-calc(77)});
                    padding: 0 0 rem-calc(32) 0;

                    @include media-breakpoint-up (sm) {
                        padding: 0;
                    }

                    .suggestions-initial {
                        max-height: 100%;
                        overflow-y: auto;
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;
                        overflow: -moz-scrollbars-none;

                        &::-webkit-scrollbar {
                            display: none;  /* Safari and Chrome */
                        }
                    }

                    .suggestions {
                        max-height: 100%;
                        overflow-y: auto;
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;
                        overflow: -moz-scrollbars-none;

                        &::-webkit-scrollbar {
                            display: none;  /* Safari and Chrome */
                        }
                    }
                }
            }
        }
    }

    &-mobileMenu {
        @include flexGenerator(column);
        background-color: $white;
        color: $black;
        height: 100%;
        max-height: 100%;
        overflow-y: hidden;

        @include media-breakpoint-up (sm) {
            border-right: rem-calc(1) solid $hr-border-color;
            max-width: rem-calc(450);
        }

        &-header {
            @include flexGenerator(row, space-between, center, 0);
            border-bottom: rem-calc(1) solid $hr-border-color;
            padding: rem-calc(15) rem-calc(20);
            position: relative;

            @include media-breakpoint-up (sm) {
                padding-top: rem-calc(26);
                padding-bottom: rem-calc(25);
            }

            &-icon {
                height: rem-calc(26);
                width: rem-calc(26);
            }

            &-title {
                font-size: rem-calc(18);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(22);
                text-transform: uppercase;
            }

            &-logoIcon {
                height: rem-calc(24);
                width: rem-calc(107);

                &:hover {
                    opacity: 1;
                }
            }
        }

        &-body {
            @include flexGenerator(column, flex-start, stretch, 0, 1);
            overflow-y: auto;
            padding: rem-calc(25) rem-calc(20);
            row-gap: rem-calc(25);

            &-item {
                @include flexGenerator(row, space-between, center, 0);
                cursor: pointer;
                font-size: rem-calc(18);
                font-weight: $primary-font-regular;
                line-height: rem-calc(22);

                &.cc-highlight {
                    color: $red;
                }

                &.cc-showAll {
                    font-weight: $primary-font-semibold;
                    text-transform: unset !important;
                }
            }

            &.lv1 &-item {
                font-size: rem-calc(18);
                font-weight: $primary-font-semibold;
                line-height: rem-calc(22);
                text-transform: uppercase;
            }

            &.lv1 {
                .country-selector-icon {
                    border-bottom: rem-calc(1) solid $hr-border-color;
                    border-top: rem-calc(1) solid $hr-border-color;
                    padding: rem-calc(30) 0;

                    .cc-language-switch-image {
                        border: rem-calc(1) solid $grey3;
                        height: auto;
                        margin-left: rem-calc(5);
                        width: rem-calc(31);
                    }
                }
            }

            &.lv2 &-item {
                text-transform: uppercase;
            }

            &-arrowIcon {
                color: $grey2;
                height: rem-calc(21);
                width: rem-calc(21);
            }

            &-mainCategory {
                border-bottom: rem-calc(1) solid $hr-border-color;
                display: flex;
                flex-direction: column;
                margin-bottom: rem-calc(5);
                padding-bottom: rem-calc(30);
                row-gap: rem-calc(25);
            }

            &-link {
                font-size: rem-calc(16);
                font-weight: $primary-font-regular;
                line-height: rem-calc(20);
                text-align: start;
            }

            .cc-menu-item {
                display: flex;
                margin-bottom: rem-calc(11);
            }

            .cc-menu-item-link {
                @include flexGenerator(row, space-between, center);
                border: 0;
                flex-grow: 1;
                font-size: rem-calc(18);
                font-weight: $primary-font-regular;
                line-height: rem-calc(22);
                padding: 0;
            }

            .cc-menu-item-2 {
                position: relative;

                .cc-main-navigation-link-2 {
                    text-transform: uppercase;
                }

                .cc-content-items-3 {
                    display: none;
                }

                &.cc-menu-item-parent {
                    .cc-menu-item-link-icon {
                        background-color: $grey2;
                        height: rem-calc(21);
                        mask-image: $svg-icon-caret-right;
                        mask-position: center;
                        mask-repeat: no-repeat;
                        mask-size: contain;
                        width: rem-calc(21);
                    }
                }
            }
        }

        &-footer {
            @include flexGenerator(column, flex-start, flex-start);
            border-bottom: rem-calc(1) solid $hr-border-color;
            padding-bottom: rem-calc(5);

            &-link {
                @include flexGenerator(row, flex-start, center, 0);
                width: 100%;
                column-gap: rem-calc(14);
                padding-bottom: rem-calc(25);
            }

            &-icon {
                height: rem-calc(24);
                width: rem-calc(26);
            }

            &-iconNumber {
                position: relative;

                .cc-icon-number {
                    background-color: $red;
                    border-radius: rem-calc(8);
                    top: 0;
                    color: $white;
                    font-size: rem-calc(10);
                    font-weight: $primary-font-semibold;
                    height: rem-calc(16);
                    line-height: rem-calc(16);
                    position: absolute;
                    right: rem-calc(-6);
                    text-align: center;
                    width: rem-calc(16);
                }
            }

            &-label {
                font-size: rem-calc(16);
                font-weight: $primary-font-regular;
                line-height: rem-calc(20);
                text-align: start;
            }
        }
    }

    &--banner {
        position: relative;
    }

    &-bar {
        @include flexGenerator(row, center, center);
        padding: rem-calc(20) rem-calc(16);
        position: relative;

        @include media-breakpoint-up (lg) {
            padding: rem-calc(16) rem-calc(20);
        }

        .cc-logo-white,
        .cc-logo-black {
            display: none;
        }

        .cc-header-userMenu .cc-headerBar-link {
            padding: rem-calc(4);
            border-radius: 50%;
            background-color: rgba(242, 242, 238, 0.5);
        }

        .cc-headerBar {
            &-logo {
                @include animationSlow();
                height: rem-calc(24);
                position: absolute;
                width: rem-calc(106);
                color: $red;

                &-svg {
                    height: 100%;
                    width: 100%;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &-left {
                @include media-breakpoint-down (md) {
                    @include flexGenerator(row, flex-start, center);
                    column-gap: rem-calc(20);
                    flex: 0 0 auto;
                    .cc-headerBar-search {
                        @include flexGenerator(column, flex-start, center);
                    }

                    .cc-headerBar-search  {
                        @include media-breakpoint-up (lg) {
                            display: none;
                        }
                    }
                }
            }

            &-right {
                @include flexGenerator(row, flex-end, center, 0);
                column-gap: rem-calc(16);
                margin-left: auto;

                a {
                    @include animationSlow();
                }

                @include media-breakpoint-up (lg) {
                    margin-left: auto;
                }

                .cc-headerBar-search {
                    display: none;
                }

                .cc-header-logo__container {
                    display: none;
                }

                @include media-breakpoint-up(lg) {
                    .cc-header-logo__container {
                        display: flex;
                        margin-left: 0;
                        align-items: center;
                        column-gap: rem-calc(26);

                        .cc-item-link {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            align-items: center;
                            column-gap: rem-calc(10);
                            font-size: rem-calc(12);
                            line-height: rem-calc(18);
                            font-weight: $primary-font-medium;
                            letter-spacing: rem-calc(1);
                            text-transform: uppercase;
                            color: inherit;

                            .cc-icon-text {
                                border-bottom-color: inherit;
                                border-bottom-style: solid;
                                border-bottom-width: 1px;
                            }

                            &:hover {
                                color: var(--color-link-hover);

                                .cc-icon-text {
                                    border-color: var(--color-link-hover);
                                }
                            }

                            .cc-icon-locator-small {
                                height: rem-calc(20);
                                width: rem-calc(16);
                            }
                        }
                    }
                }
            }

            &-icon {
                height: rem-calc(24);
                width: rem-calc(24);
                padding: rem-calc(2);

                &-menu {
                    stroke: currentColor;

                    &:hover {
                        stroke: $red;
                    }
                }


                &.cc-icon-search {
                    display: none;
                }

                &:hover {
                    color: $red !important;
                    fill: $red !important;
                }
            }

            &-iconNumber {
                position: relative;

                .cc-icon-number {
                    background-color: $red;
                    border-radius: rem-calc(8);
                    top: 0;
                    color: $white;
                    font-size: rem-calc(10);
                    font-weight: $primary-font-semibold;
                    height: rem-calc(16);
                    line-height: rem-calc(16);
                    position: absolute;
                    right: rem-calc(-7);
                    text-align: center;
                    width: rem-calc(16);
                }
            }

            &-label {
                display: none;
                font-size: rem-calc(12);
                font-weight: $primary-font-medium;
                line-height: rem-calc(15);
                margin-top: rem-calc(6);

                @include media-breakpoint-up (sm) {
                    display: block;
                }
            }

            &-link {
                @include flexGenerator(column, flex-start, center);
                cursor: pointer;

                &.active {
                    .cc-headerBar-icon {
                        color: $red !important;
                    }
                }
            }

            &-customerCare {
                font-size: rem-calc(13);
                font-weight: $primary-font-regular;
                line-height: rem-calc(18);
                letter-spacing: rem-calc(1.3);
                color: $black-warm;
            }
        }
    }

    &--transparent {
        [data-action="Home-Show"] & {
            color: $white;
            border-bottom: 0;
            background: linear-gradient(180deg, rgba(20, 0, 0, 0.6) 0%, rgba(20, 0, 0, 0) 100%);

            .cc-header-bar {
                .cc-logo-red {
                    display: none;
                }

                .cc-logo-white {
                    display: inline;
                }

                .cc-headerBar-iconNumber {
                    .cc-icon-number {
                        background-color: $white;
                        color: $black-warm;
                    }

                    &:hover {
                        @include media-breakpoint-up (lg) {
                            color: $red;
                        }
                    }
                }

                .cc-header-userMenu .cc-headerBar-link {
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            .cc-headerBar-logo {
                color: $white;
            }

            .cc-header__search-input {
                background-color: rgba(255, 255, 255, 0.1);
                color: $white;

                svg {
                    fill: $white;
                }
            }

            .cc-headerBar-right {
                a {
                    color: $white;
                }
            }

            .cc-main-navigation-link {
                color: $white;
            }

            &:hover {
                @include media-breakpoint-up (lg) {
                    background: $white;
                    color: $black-warm;

                    .cc-header-bar {
                        .cc-logo-red {
                            display: inline;
                        }

                        .cc-logo-white {
                            display: none;
                        }

                        .cc-header-userMenu .cc-headerBar-link {
                            background-color: rgba(242, 242, 238, 0.5);
                        }

                        .cc-headerBar-iconNumber {
                            .cc-icon-number {
                                background-color: $red;
                                color: $white;
                            }

                            &:hover {
                                color: $red;
                            }
                        }
                    }

                    .cc-headerBar-logo {
                        color: $red;
                    }

                    .cc-header__search-input {
                        background-color: rgba($warm-grey-01, 0.5);
                        color: $black-warm;
                    }

                    .cc-header__search-input svg {
                        fill: $black-warm;
                    }

                    .cc-main-navigation-link,
                    .cc-headerBar-right a {
                        color: $black-warm;
                        cursor: pointer;

                        &:hover {
                            color: $red;
                        }
                    }
                }
            }
        }
    }

    &--banner {
        [data-action="Home-Show"] & {
            &:not(.d-none):has(.cc-header-promo) {
                + .cc-header {
                    & + #maincontent {
                        .cc-component-image-hero {
                            height: calc(100vh - var(--header-height) - var(--announcement-bar-height));
                        }

                        .experience-component {
                            &:not(:first-child) {
                                .cc-component-image-hero {
                                    height: 100vh;
                                }
                            }
                        }
                    }

                    &.cc-header--trasparentHeader {
                        & + #maincontent {
                            margin-top: calc(-1 * var(--header-height));

                            .cc-component-image-hero {
                                height: calc(100vh - var(--announcement-bar-height));
                            }

                            .experience-component {
                                &:not(:first-child) {
                                    .cc-component-image-hero {
                                        height: 100vh;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.d-none,
            &:not(:has(.cc-header-promo)) {
                + .cc-header {
                    & + #maincontent {
                        .experience-component {
                            &:not(:first-child) {
                                .cc-component-image-hero {
                                    height: 100vh;
                                }
                            }
                        }
                    }

                    &.cc-header--trasparentHeader {
                        & + #maincontent {
                            margin-top: calc(-1 * var(--header-height));

                            .cc-component-image-hero {
                                height: 100vh;
                            }
                        }
                    }
                }
            }
        }
    }

    &--white {
        background: $white;
        color: $black-warm;

        .cc-header-bar {
            .cc-logo-red {
                display: inline;
            }

            .cc-logo-white {
                display: none;
            }

            .cc-header-userMenu .cc-headerBar-link {
                background-color: rgba(242, 242, 238, 0.5);
            }

            .cc-headerBar-iconNumber {
                .cc-icon-number {
                    background-color: $red;
                    color: $white;
                }

                &:hover {
                    @include media-breakpoint-up (lg) {
                        color: $red;
                    }
                }
            }
        }

        .cc-headerBar-logo {
            color: $red;
        }

        .cc-header__search-input {
            background-color: rgba($warm-grey-01, 0.5);
            color: $black-warm;
        }

        .cc-header__search-input svg {
            fill: $black-warm;
        }

        .cc-main-navigation-link,
        .cc-headerBar-right a {
            color: $black-warm;
            cursor: pointer;

            &:hover {
                color: $red;
            }
        }
    }

    &--dark {
        &.cc-header--transparent {
            [data-action="Home-Show"] & {
                background: linear-gradient(180deg, rgba(253, 252, 251, 0.6) 0%, rgba(253, 252, 251, 0) 100%);
                color: $black-warm;

                .cc-header-bar {
                    .cc-logo-red {
                        display: none;
                    }

                    .cc-logo-white {
                        display: inline;
                    }

                    .cc-headerBar-iconNumber {
                        .cc-icon-number {
                            background-color: $white;
                            color: $black-warm;
                        }

                        &:hover {
                            color: $red;
                        }
                    }

                    .cc-header-userMenu .cc-headerBar-link {
                        background-color: rgba(255, 255, 255, 0.1);
                    }
                }

                .cc-headerBar-logo {
                    color: $black-warm;
                }

                .cc-header__search-input {
                    background-color: rgba(255, 255, 255, 0.1);
                    color: $black-warm;

                    svg {
                        fill: $black-warm;
                    }
                }

                .cc-main-navigation-link {
                    color: $black-warm;
                }

                .cc-headerBar-right {
                    a {
                        color: $black-warm;
                        cursor: pointer;

                        &:hover {
                            color: $red;
                        }
                    }
                }

                &:hover {
                    @include media-breakpoint-up (lg) {
                        background: $white;
                        color: $black-warm;

                        .cc-header-bar {
                            .cc-logo-red {
                                display: inline;
                            }

                            .cc-logo-white {
                                display: none;
                            }

                            .cc-header-userMenu .cc-headerBar-link {
                                background-color: rgba(242, 242, 238, 0.5);
                            }

                            .cc-headerBar-iconNumber {
                                .cc-icon-number {
                                    background-color: $red;
                                    color: $white;
                                }

                                &:hover {
                                    color: $red;
                                }
                            }
                        }

                        .cc-headerBar-logo {
                            color: $red;
                        }

                        .cc-header__search-input {
                            background-color: rgba($warm-grey-01, 0.5);
                            color: $black-warm;
                        }

                        .cc-header__search-input svg {
                            fill: $black-warm;
                        }

                        .cc-main-navigation-link,
                        .cc-headerBar-right a {
                            color: $black-warm;

                            &:hover {
                                color: $red;
                            }

                        }
                    }
                }
            }
        }
    }

    &.cc-header-checkout {
        .cc-header-bar {
            align-items: center;

            @include media-breakpoint-up (lg) {
                padding: rem-calc(16) rem-calc(24);
            }
        }

        .cc-headerBar-customerCare {

            .cc-icon {
                @include fixWidth(rem-calc(24));
                @include fixHeight(rem-calc(24));
            }
        }

        // The logo is placed at the center ofthe header,
        // regardless of the width of the elements at the left and right of it
        .cc-headerBar-logo {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        // If customer care is missing, cart icon will stay on the right
        .cc-headerBar-cart {
            margin: 0 0 0 auto;
        }

    }

    &.cc-simple-header {

        .cc-header-bar {
            align-items: center;
            border-bottom: rem-calc(1) solid $hr-border-color;
        }

        .cc-headerBar-logo {
            position: relative;
        }
    }

    &-userMenu {
        position: relative;

        &-container {
            color: $black-warm;
            display: none;
            padding: rem-calc(16);
            margin-top: rem-calc(15);
            z-index: 11;

            @include media-breakpoint-down (md) {
                background-color: $white-warm;
                border-radius: rem-calc(8) rem-calc(8) 0 0;
                bottom: 0;
                height: auto;
                left: 0;
                padding-bottom: 0;
                position: fixed;
                right: 0;
                width: 100dvw;
            }

            @include media-breakpoint-up (lg) {
                background-color: $white;
                border: rem-calc(1) solid $black-warm;
                border-radius: rem-calc(2);
                left: 50%;
                margin-top: rem-calc(12);
                padding-bottom: 0;
                position: absolute;
                top: 100%;
                transform: translateX(-50%);
                z-index: 11;
            }

            .cc-account-button {
                color: $black-warm !important;
                display: block;
                font-size: rem-calc(15);
                font-weight: 500;
                letter-spacing: rem-calc(0.15);
                line-height: rem-calc(22);
                margin-bottom: rem-calc(16);
                overflow: hidden;
                padding: rem-calc(0) rem-calc(8);
                text-align: center;
                width: 100%;

                @include media-breakpoint-up (lg) {
                    white-space: nowrap;

                    &:hover {
                        color: $red !important;
                        cursor: pointer;
                    }
                }

                &__logout {
                    border-top: rem-calc(1) solid $warm-grey-02;
                    padding: rem-calc(16) 0 0 0;
                    width: 100%;

                    .cc-account-button {
                        text-decoration: underline;
                    }
                }
            }
        }

        &-overlay {
            @include media-breakpoint-down(md) {
                background-color: rgba(20, 0, 0, 0.2);
                backdrop-filter: blur(#{rem-calc(5)});
                bottom: 0;
                height: 100dvh;
                left: 0;
                overflow: hidden;
                position: fixed;
                right: 0;
                top: 0;
                width: 100dvw;
                z-index: 10;
            }

            @include media-breakpoint-up (lg) {
                background-color: transparent;
                backdrop-filter: none;
                bottom: 0;
                height: 100dvh;
                overflow: hidden;
                position: fixed;
                right: 0;
                top: 0;
                width: 100dvw;
                z-index: 10;
            }
        }
    }

    &.cc-header-sticky {
        .cc-header-bar {
            @include media-breakpoint-up (lg) {
                border-bottom: rem-calc(1) solid $hr-border-color;
                padding-top: rem-calc(15);
            }

            .cc-headerBar {
                &-label {
                    display: none;
                }

                &-icon {
                    @include media-breakpoint-up (lg) {
                        &.cc-icon-search {
                            cursor: pointer;
                            display: block;
                        }
                    }
                }

                &-right {
                    @include media-breakpoint-up (lg) {
                        z-index: 1;
                    }
                }
            }
        }

        .cc-header-main {
            @keyframes headerStickyMove {
                from {
                    top: rem-calc(-200);
                }

                to {
                    top: 0;
                }
            }

            animation: headerStickyMove .5s;
            background-color: $white;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;

            @include media-breakpoint-up(lg) {
                .cc-main-menu {
                    @include flexGenerator(row, center, center, 0);
                    @include noAnimation;
                    border: 0;
                    margin-bottom: 0;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: rem-calc(-60);

                    .cc-container-full {
                        border-bottom: 0;
                        display: flex;
                        height: rem-calc(60);
                        margin: 0;
                        max-height: unset;
                        padding: 0;
                        width: unset;

                        .cc-menu-bar-container {
                            padding: 0;
                        }
                    }

                    .cc-content-menu {
                        .cc-menu-items {
                            .cc-menu-item {
                                &.cc-menu-item-1 {
                                    height: rem-calc(60);
                                    padding-bottom: 0;

                                    .cc-main-navigation-link {
                                        margin: rem-calc(15) 0;
                                    }
                                }
                            }
                        }
                    }

                    .cc-content-menu {
                        .cc-menu-items {
                            .cc-menu-item {
                                .cc-content-items-2 {
                                    top: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .cc-content-main-search {
            display: none;
        }
    }

    .ca-menuCategoryManual {
        color: $black;
        text-transform: uppercase;

        @include media-breakpoint-up (lg) {
            @include flexGenerator(row, center);
            column-gap: rem-calc(40);

            &-item {
                margin-top: auto;
                max-width: 20%;

                img {
                    height: auto;
                    width: 100%;
                }

                span {
                    @include animation;
                    border: rem-calc(1) solid transparent;
                    display: block;
                    font-size: rem-calc(14);
                    font-weight: $primary-font-semibold;
                    line-height: rem-calc(16);
                    margin: rem-calc(10) auto 0;
                    padding: rem-calc(6) rem-calc(10);
                    width: max-content;
                }

                &:hover {
                    span {
                        border-color: $black;
                    }
                }
            }
        }

        @include media-breakpoint-down (md) {
            @include flexGenerator(column, flex-start, flex-start);
            row-gap: rem-calc(25);

            &-item {
                font-size: rem-calc(18);
                font-weight: $primary-font-regular;
                line-height: rem-calc(22);

                img {
                    display: none;
                }
            }
        }
    }

    &__search-input {
        @include animationSlow();
        display: none;
        align-items: center;
        gap: rem-calc(8);
        background-color: rgba($warm-grey-01, 0.5);
        border-radius: rem-calc(2);
        padding: rem-calc(4) rem-calc(8);
        font-size: rem-calc(15);
        line-height: rem-calc(22);
        letter-spacing: rem-cac(1.2);
        font-weight: $primary-font-medium;
        color: $black-warm;
        min-width: rem-calc(185);

        &:hover {
            color: $red !important;

            svg {
                fill: $red;
            }
        }

        @include media-breakpoint-up (sm) {
            display: flex;
        }
    }

    &-suggestions {
        margin-top: rem-calc(31);

        @include media-breakpoint-up (sm) {
            margin-top: rem-calc(44);
        }
    }
}

.cc-header--banner {
    .cc-header-promo {
        font-size: 0;
        overflow: hidden;
        text-align: center;
        background-color: $warm-grey-01;

        p {
            margin-bottom: 0;
        }

        &.cc-header-promo-dark {
            background-color: $black-warm;

            .cc-text {
                color: $white;

                a:hover {
                    color: $warm-grey-04;
                }

                .cc-text-link {
                    a {
                        text-decoration: none;
                    }

                    @include media-breakpoint-up (lg) {
                        &:hover {
                            cursor: pointer;
                            color: $warm-grey-04;
                        }
                    }
                }
            }

            .cc-header-promo-dropdown__inner {
                background: $black-warm;
                color: $white;
            }

            .cc-header-promo-dropdown__link {
                border-color: $white;

                @include media-breakpoint-up (lg) {
                    &:hover {
                        border-color: $red;
                    }
                }
            }
        }

        .cc-slider {
            height: rem-calc(32);
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(xs) {
                overflow: visible;
            }
        }

        .cc-slide {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -200%);
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &.activePromo {
                animation: header-promo-carousel 10.5s cubic-bezier(.45,.88,.8,.96) infinite;
                transform: translate(0, -50%);
            }

            &:hover {
                animation-play-state: paused;
            }
        }

        @keyframes header-promo-carousel {
            0% {
                transform: translate(0, 50%);
            }
            7.5%, 33% {
                transform: translate(0, -50%);
            }
            40.5%, 100% {
                transform: translate(0, -200%);
            }
        }

        .cc-text {
            @include animation;
            color: $black-warm;
            display: block;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            font-weight: $primary-font-medium;
            padding: rem-calc(2) 0;
            text-decoration: none;
            letter-spacing: 0.01em;

            a {
                text-decoration: underline;
                text-underline-offset: rem-calc(4);
            }

            @include media-breakpoint-up (lg) {
                a:hover {
                    cursor: pointer;
                    color: $red;
                }
            }

            .cc-text-link {
                margin-bottom: 0;

                a {
                    transition: none;
                    text-decoration: none;
                }

                @include media-breakpoint-up (lg) {
                    &:hover {
                        cursor: pointer;
                        color: $red;
                    }
                }
            }
        }

        &-arrow {
            width: rem-calc(32);
            height: rem-calc(32);
            padding: 0 rem-calc(9);

            &-icon {
                width: rem-calc(14);
                height: rem-calc(7);
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .iconClassCommon {
                        color: $red;
                    }
                }
            }
        }

        &-dropdown {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            transform: translateY(-100%);
            @include animationSlow();
            z-index: 6;
            height: 100vh;
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            &__inner {
                padding: rem-calc(52) rem-calc(16) rem-calc(40) rem-calc(16);
                background: $warm-grey-01;
                color: $black-warm;

                @include media-breakpoint-up(sm) {
                    grid-template-columns: repeat(12, minmax(0, 1fr));
                    column-gap: rem-calc(12);
                    display: grid;
                }

                @include media-breakpoint-up(md) {
                    padding: rem-calc(76) rem-calc(24) rem-calc(56) rem-calc(24);
                }
            }

            &__items {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                gap: rem-calc(32);

                @include media-breakpoint-up(sm) {
                    grid-column-start: 2;
                    grid-column-end: 12;
                    flex-direction: row;
                    gap: rem-calc(32);
                }

                @include media-breakpoint-up(md) {
                    gap: rem-calc(64);
                }
            }

            &__item {
                font-family: $primary-font;
                font-size: rem-calc(13);
                line-height: rem-calc(18);
                display: flex;
                flex-direction: column;
                gap: rem-calc(4);
                align-items: flex-start;
                text-align: left;

                @include media-breakpoint-up(md) {
                    font-size: rem-calc(15);
                    line-height: rem-calc(22);
                }
            }

            &__title {
                font-weight: $primary-font-bold;
            }

            &__description {
                font-weight: $primary-font-regular;
                letter-spacing: rem-calc(0.15);
            }

            &__cta {
                padding-top: rem-calc(8);
            }

            &__link {
                font-weight: $primary-font-medium;
                letter-spacing: rem-calc(0.15);
                border-bottom: rem-calc(1) solid $black-warm;

                &:hover {
                    @include media-breakpoint-up(lg) {
                        color: $red;
                        border-color: $red;
                    }
                }
            }

            &__cross {
                display: flex;
                align-content: center;
                justify-content: center;
                position: absolute;
                top: rem-calc(24);
                right: rem-calc(24);
                z-index: 1;
                width: rem-calc(20);
                height: rem-calc(20);

                @include media-breakpoint-down(xs) {
                    top: rem-calc(16);
                    right: rem-calc(16);
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &-overlay {
            background-color: rgba(20, 0, 0, 0.2);
            backdrop-filter: blur(#{rem-calc(7.5)});
            bottom: 0;
            height: 100dvh;
            overflow: hidden;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 6;
            opacity: 0;
            visibility: hidden;
            @include animationSlow();
        }

        &:has(.cc-header-promo-dropdown) {
            .cc-text {
                margin-left: rem-calc(32);
                cursor: pointer;
            }
        }
    }

    &.dropdown-active {
        .cc-header-promo-dropdown {
            transform: translateY(0);
        }

        .cc-header-promo-overlay {
            opacity: 1;
            visibility: visible;
        }
    }

    .cc-header-brands {
        position: absolute;
        z-index: 1;
        left: rem-calc(24);
        top: 0;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        column-gap: rem-calc(22);

        @include media-breakpoint-down(md) {
            position: relative;
            left: 0;
            height: rem-calc(40);
            padding: 0 rem-calc(16);
            justify-content: center;
            column-gap: rem-calc(12);
            background: $warm-grey-05;
        }

        @include media-breakpoint-down(xs) {
            justify-content: space-between;
            overflow: auto;
        }

        &__link {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: rem-calc(3) 0;

            &.active {
                border-bottom: rem-calc(1) solid $warm-grey-05;

                @include media-breakpoint-down(md) {
                    border-color: $white-warm;
                }
            }
        }

        &__img {
            @include media-breakpoint-down(md) {
                /* Change color to white*/
                filter: $filter-white-warm;
            }
        }

        & + .cc-header-promo {
            .cc-text {
                @include media-breakpoint-only(lg) {
                    max-width: rem-calc(380);
                }
            }
        }

        &:only-child {
            position: relative;
            left: 0;
            padding-left: rem-calc(24);
            height: rem-calc(32);
            background: $warm-grey-01;

            @include media-breakpoint-down(md) {
                padding-left: rem-calc(16);
                height: rem-calc(40);
                background: $warm-grey-05;
            }
        }
    }

    &:has(.cc-header-promo-dark) {
        .cc-header-brands__img {
            @include media-breakpoint-up(lg) {
                /* Change color to white*/
                filter: $filter-white-warm;
            }
        }

        .cc-header-brands__link {
            &.active {
                @include media-breakpoint-up(lg) {
                    border-color: $white-warm;
                }
            }
        }

        .cc-header-promo-arrow {
            color: $white-warm;
        }
    }
}


.error-message {
    background-color: $primary-color;
    margin: 0;
    padding: 0;

    &-cookies {
        color: $white;
        font-size: rem-calc(14);
        font-weight: $primary-font-medium;
        line-height: rem-calc(21);
        margin: 0;
        padding: rem-calc(14) 0;
        text-align: center;

        @include media-breakpoint-down(xs) {
            font-size: rem-calc(13);
            padding: rem-calc(20) 0;
        }
    }
}

.cookies-disabled {
    display: none;
}

.scripts-errors {
    display: contents;
    text-align: center;
}

.cc-search-custom-container {
    .cc-search-row-no-results {
        @include flexGenerator(column);

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            justify-content: space-between;
        }

        .cc-col-no-products {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 75%;
            }

            .suggestions-no-products-found {
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                margin-bottom: rem-calc(20);

                @include media-breakpoint-down(md) {
                    padding: 0 rem-calc(20);
                }

                @include media-breakpoint-up(lg) {
                    margin-bottom: rem-calc(25);
                    padding-left: rem-calc(30);
                }
            }
        }

        .cc-col-suggestions {
            width: 100%;

            @include media-breakpoint-down(md) {
                order: 2;
                padding: rem-calc(20) rem-calc(20) 0;
            }

            @include media-breakpoint-up(lg) {
                padding-left: rem-calc(30);
                width: 25%;
            }

            .cc-suggestions-label {
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                margin-bottom: rem-calc(20);
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    margin-bottom: rem-calc(25);
                }
            }

            .cc-items {
                .cc-item {
                    margin-bottom: rem-calc(21);

                    @include media-breakpoint-up(lg) {
                        margin-bottom: rem-calc(8);
                    }
                }
            }

            .b-suggestions__container {
                .b-suggestions__title {
                    font-size: rem-calc(14);
                    font-weight: $primary-font-semibold;
                    margin-bottom: rem-calc(20);
                    text-transform: uppercase;

                    @include media-breakpoint-up(lg) {
                        margin-bottom: rem-calc(25);
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;

                    .b-suggestions__hit {
                        font-size: rem-calc(13);
                        margin-bottom: .5rem;
                    }
                }
            }
        }

        .suggestions-quick-links-no-results {
            display: flex;
            width: 100%;

            @include media-breakpoint-down(md) {
                order: 1;
            }

            @include media-breakpoint-up(lg) {
                justify-content: space-between;
            }

            .b-suggestions__container {
                .b-suggestions__title {
                    font-weight: $primary-font-medium;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    color: $warm-grey-05;
                    margin-bottom: rem-calc(16);
                    text-transform: uppercase;
                    letter-spacing: 0.1em;

                    @include media-breakpoint-down(md) {
                        margin-top: rem-calc(31);
                    }
                }

                ul {
                    display: flex;
                    flex-direction: column;

                    .b-suggestions__hit {
                        font-weight: $primary-font-medium;
                        font-size: rem-calc(15);
                        line-height: rem-calc(22);
                        letter-spacing: 0.01em;
                        margin-bottom: rem-calc(8);
                        display: block;

                        @include media-breakpoint-down(md) {
                            margin-bottom: rem-calc(16);
                        }

                        @include media-breakpoint-up (lg) {
                            &:hover {
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }

    .cc-search__reset {
        position: absolute;
        top: 50%;
        right: rem-calc(79);
        font-size: rem-calc(12);
        font-weight: 500;
        line-height: rem-calc(18);
        letter-spacing: rem-calc(1.2);
        text-transform: uppercase;
        transform: translateY(14%);
        color: $black;

        &:disabled {
            display: none;
        }

        @include media-breakpoint-down(md) {
            top: 31%;
            right: rem-calc(69);
        }
    }

    .cc-search-row {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            "col1 col2"
            "col1_2 col2"
            ". col2 ";
        border-top: rem-calc(1) solid $warm-grey-02;
        padding: rem-calc(22) rem-calc(22);

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
            padding: rem-calc(24) 0 0;
        }

        // @include media-breakpoint-up(lg) {
        //     margin: 0 0 rem-calc(45);
        // }

        .cc-product-search-first-col {
            width: 100%;
            grid-area: col1;

            @include media-breakpoint-down(md) {
                order: 3;
            }

            @include media-breakpoint-down(lg) {
                padding-left: rem-calc(16);
                padding-right: rem-calc(16);
            }

            .cc-header-title {
                font-weight: $primary-font-medium;
                font-size: rem-calc(12);
                line-height: rem-calc(18);
                color: $warm-grey-05;
                margin-bottom: rem-calc(16);
                text-transform: uppercase;
                letter-spacing: 0.1em;

                .cc-header-title__label {
                    text-transform: uppercase;
                }

                @include media-breakpoint-down(md) {
                    margin-top: rem-calc(31);
                }
            }

            .name {
                font-weight: $primary-font-medium;
                font-size: rem-calc(15);
                line-height: rem-calc(22);
                letter-spacing: 0.01em;
                margin-bottom: rem-calc(8);
                display: block;

                @include media-breakpoint-down(md) {
                    margin-bottom: rem-calc(16);
                }
            }

            .do-you-mean-container {
                display: flex;
                font-size: rem-calc(13);
                font-weight: $primary-font-medium;

                @include media-breakpoint-down(md) {
                    padding: 0 rem-calc(20);
                }

                .items {
                    .item {
                        &.term {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .cc-product-items {
                display: flex;
                flex-wrap: wrap;
                margin-top: rem-calc(10);

                @include media-breakpoint-up(lg) {
                    margin-top: rem-calc(12.5);
                }

                .item {
                    margin-bottom: rem-calc(20);
                    width: 100%;

                    @include media-breakpoint-down(md) {
                        padding: 0 rem-calc(20);
                    }

                    @include media-breakpoint-up(lg) {
                        margin-bottom: rem-calc(25);
                        width: 50%;

                        &:nth-child(n+1) {
                            padding-right: rem-calc(30);
                        }

                        &:nth-child(2n+1) {
                            padding-right: 6.8%;
                        }
                    }

                    .cc-item-content {
                        align-items: center;
                        display: flex;

                        .cc-container-dis-picture,
                        img {
                            width: rem-calc(90);
                        }

                        .cc-container-dis-picture {

                            picture {

                                img {
                                    min-width: rem-calc(55);
                                }
                            }
                        }

                        .cc-item-content-text {
                            font-size: rem-calc(13);
                            padding-left: rem-calc(30);

                            .cc-name {
                                font-weight: $primary-font-semibold;
                            }

                            .cc-tile-product-brand {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .cc-suggestions__actions {
                font-size: rem-calc(14);
                font-weight: $primary-font-semibold;
                margin-top: rem-calc(10);
                text-transform: uppercase;

                @include media-breakpoint-down(md) {
                    margin-bottom: rem-calc(25);
                }

                @include media-breakpoint-up(lg) {
                    margin-top: rem-calc(15);
                }

                a {
                    align-items: center;
                    display: flex;

                    @include media-breakpoint-down(md) {
                        justify-content: center;
                    }

                    .icon-arrow-right-big {
                        margin-left: rem-calc(10);
                    }
                }
            }

            &--second-row {
                grid-area: col1_2;

                @include media-breakpoint-down(md) {
                    order: 1;
                }
            }
        }

        .cc-product-search-second-col {
            width: 100%;
            padding-top: rem-calc(8);
            grid-area: col2;

            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }

            @include media-breakpoint-down(md) {
                order: 2;
            }

            .cc-header-searchProduct {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                @include media-breakpoint-down(lg) {
                    padding-left: rem-calc(16);
                    padding-right: rem-calc(16);
                }

                .cc-header-title {
                    font-weight: $primary-font-medium;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    color: $warm-grey-05;
                    margin-bottom: rem-calc(16);
                    text-transform: uppercase;
                    letter-spacing: 0.1em;
                }

                .suggestions__link-all {
                    display: inline-block;
                    font-size: rem-calc(12);
                    line-height: rem-calc(18);
                    font-weight: $primary-font-medium;
                    letter-spacing: rem-calc(0.13);
                    text-underline-offset: rem-calc(5);
                    margin-bottom: rem-calc(16);
                    margin-left: rem-calc(8);
                    text-decoration: underline;
                }

                .suggestions__link-all-icon {
                    display: inline-block;
                    width: rem-calc(16);
                    height: rem-calc(16);
                    margin-left: rem-calc(9);
                }

                .cc-tile-product-name {
                    margin-top: rem-calc(2);
                    margin-bottom: rem-calc(3);
                }
            }

            .cc-item-content-text {
                padding: rem-calc(7) rem-calc(9) 0;

                .cc-name {
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    text-overflow: ellipsis;
                    font-size: rem-calc(13);
                    overflow: hidden;
                    line-height: rem-calc(18);
                    font-weight: 500;
                    letter-spacing: rem-calc(0.15);
                    max-width: 95%;
                }

                .cc-tile-product-brand {
                    margin-bottom: 0;
                }

                .cc-price {
                    font-size: rem-calc(13);
                }

                .cc-price-wrapper {
                    display: flex;
                    flex-direction: row;
                    font-size: rem-calc(13);
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                    gap: rem-calc(4);
                }

                .cc-price-container {
                    .sales {
                        .cc-price {
                            font-style: normal;
                            font-weight: 500;
                            white-space: nowrap;
                        }

                        &.cc-sales-reduced {
                            .cc-price {
                                color: $red;
                            }
                        }
                    }

                    .cc-price {
                        display: block;
                        font-weight: $primary-font-medium;
                        padding-left: 0;

                        &.cc-price-reduced {
                            position: relative;

                            &::after {
                                border-bottom: rem-calc(1) solid $black;
                                content: "";
                                height: 30%;
                                left: 50%;
                                position: absolute;
                                transform: rotate(-8deg) translateX(-50%);
                                width: 120%;
                            }
                        }
                    }
                }

                .cc-price--wrapper{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    align-items: center;
                    font-size: rem-calc(13);
                    line-height: rem-calc(22);
                    gap: rem-calc(4);
                    margin-top: 0;
                    margin-bottom: rem-calc(6);

                    .cc-price--discount {
                        display: none;
                    }

                    .cc-price-reduced {
                        text-decoration: line-through;

                        &:after {
                            display: none;
                        }
                    }
                }

                @include original-and-pp-price-container(12, 9, 18, 6, flex-start);
            }

            .cc-header-searchProducts {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: rem-calc(24) rem-calc(1);

                .item {
                    overflow: hidden;

                    &:hover {
                        .cc-color-label {
                            display: none;
                        }

                        .cc-color-listing {
                            display: block;
                        }
                    }

                    .cc-tile-product-name,
                    .cc-name {
                        font-size: rem-calc(13);
                        font-weight: 500;

                        &:hover {
                            color: $red;
                        }
                    }
                }

                .cc-product-label {
                    display: none;
                }

                .tile-body {
                    padding: rem-calc(6) rem-calc(10) 0;

                    @include media-breakpoint-up (lg) {
                        &:hover {
                            color: $red;
                        }
                    }

                    .cc-price--wrapper {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        align-items: center;
                        font-size: rem-calc(13);
                        line-height: rem-calc(18);
                        letter-spacing: 1%;
                        gap: rem-calc(4);
                        margin-top: 0;
                        margin-bottom: rem-calc(6);

                        .cc-price--discount {
                            display: none;
                        }

                        .cc-price-reduced {
                            display: none;
                        }

                        .cc-sales-reduced {
                            color: $red;
                            font-weight: 500;
                        }
                    }

                    @include original-and-pp-price-container(9, 9, 12, 6, flex-start);

                    .cc-original-price-percentage {
                        font-weight: 400;
                        color: $red;
                    }

                }

                .cc-tile-colors {
                    margin: rem-calc(8) 0 0;
                }

                .cc-color-swatches {
                    .cc-color-label {
                        display: block;
                        font-size: rem-calc(11);
                        line-height: rem-calc(16);
                        letter-spacing: 0.02em;
                        margin: 0;

                        @include media-breakpoint-up(lg) {
                            font-size: rem-calc(13);
                            line-height: rem-calc(18);
                            letter-spacing: 0.01em;
                        }
                    }

                    .cc-color-listing {
                        display: none;
                        min-height: rem-calc(16);

                        @include media-breakpoint-up(lg) {
                            min-height: rem-calc(18);
                        }

                        .cc-color {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;

                            .cc-color-link-content-activate {
                                width: rem-calc(16);
                                height: rem-calc(16);
                                margin: 0 rem-calc(6) 0 0;
                                border: rem-calc(1) solid transparent;
                                border-radius: rem-calc(2);
                                padding: 0;
                                overflow: hidden;
                                position: relative;

                                .cc-color-link-content {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: rem-calc(16);
                                    height: rem-calc(16);
                                    border-radius: rem-calc(2);
                                    overflow: hidden;
                                    border: none;
                                }

                                &:hover {
                                    border-color: $grey3;
                                    padding: rem-calc(2);
                                }

                                &.active {
                                    border-color: $black;
                                    padding: rem-calc(2);

                                    .cc-color-link-content {
                                        width: rem-calc(12);
                                        height: rem-calc(12);
                                    }
                                }
                            }
                        }
                    }
                }

                .cc-tile-sizes,
                .cc-wishlist-icon,
                .cc-color-swatches,
                .image-2 {
                    display: none;
                }
            }

            .cc-categories-search-container,
            .cc-popsearches-search-container {
                .cc-header-title {
                    font-size: rem-calc(14);
                    font-weight: $primary-font-semibold;
                    margin-bottom: rem-calc(25);
                    text-transform: uppercase;
                }
            }

            .cc-categories-search-container {
                @include media-breakpoint-down(md) {
                    padding: 0 rem-calc(20);
                }

                .cc-items {
                    font-size: rem-calc(13);
                    margin-bottom: rem-calc(40);

                    .cc-item {
                        margin-bottom: rem-calc(21);

                        @include media-breakpoint-up(lg) {
                            margin-bottom: rem-calc(8);
                        }

                        .cc-name {
                            .cc-name__label {
                                font-weight: $primary-font-medium;
                            }
                        }
                    }
                }
            }

            .cc-popsearches-search-container {
                @include media-breakpoint-down(md) {
                    padding: 0 rem-calc(20);
                }

                .cc-items {
                    font-size: rem-calc(13);

                    .cc-item {
                        margin-bottom: rem-calc(21);

                        @include media-breakpoint-up(lg) {
                            margin-bottom: rem-calc(8);
                        }
                    }
                }
            }
        }

        &--change-order {
            .cc-product-search-second-col {
                @include media-breakpoint-down(md) {
                    order: 1;
                }
            }

            .cc-product-search-first-col--second-row {
                @include media-breakpoint-down(md) {
                    order: 2;
                }
            }
        }

        &:not(.cc-search-row--change-order) {
            .cc-product-search-first-col--second-row {
                .cc-header-title {
                    @include media-breakpoint-down(md) {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

.cc-search-custom-container
.cc-search-row .cc-product-search-first-col
.cc-product-items .item .cc-item-content .cc-item-content-text {
    width:  rem-calc(300);
}

// BATA Redesign - Menu
.cc-menu {
    @include media-breakpoint-down (md) {
        background-color: $white-warm;
        height: 100dvh;
        left: 0;
        overflow-x: hidden;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        transition: 300ms;
        width: 100dvw;
    }

    @include media-breakpoint-up (lg) {
        left: rem-calc(-19);
        position: absolute;
        top: rem-calc(-64);
        pointer-events: none;
    }
    &.open {
        z-index: 11;
        @include media-breakpoint-down (md) {
            overflow-y: hidden;
            transform: translateX(0%);
            transition: 300ms;
        }
    }
    &__editorialMode {
        top: rem-calc(-30);
        left: rem-calc(66);

    }
    &__header {
        position: relative;
        width: 100%;
        @include media-breakpoint-down (md) {
            border-bottom: rem-calc(1) solid $warm-grey-02;
            display: flex;
            height: rem-calc(48);
            justify-content: center;
            padding: rem-calc(12) rem-calc(16);

            &__logo {
                &-icon {
                    height: rem-calc(20);
                    width: rem-calc(89);
                }
            }
        }
        @include media-breakpoint-up(lg) {
            background-color: $white-warm;
            height: 100%;
            position: absolute;
            transform: translateX(-101%);
            transition: 300ms;
            width: rem-calc(1131);

            .cc-menu.open & {
                transform: translateX(0%);
                transition: 300ms;
                z-index: 12;
            }
        }
    }

    &__close {
        padding: rem-calc(2);
        position: absolute;
        top: rem-calc(12);
        right: rem-calc(16);
        color: $black-warm;

        @include media-breakpoint-up(lg) {
            opacity: 0;
            top: rem-calc(50);
            right: rem-calc(24);

            .cc-menu.open & {
                opacity: 1;
                transition: 300ms;
                z-index: 12;
            }
        }

        &:hover {
            @include media-breakpoint-up(md) {
                color: $red;
            }
        }
    }
    &__overlay {
        @include media-breakpoint-up(lg) {
            background-color: rgba(20, 0, 0, 0.2);
            backdrop-filter: blur(#{rem-calc(5)});
            bottom: 0;
            height: 100dvh;
            overflow: hidden;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 10;
        }
    }
    &__firstLevelFake {
        overflow: auto;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        overflow: -moz-scrollbars-none;
        scrollbar-width: none;
        padding-right: rem-calc(45);

        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }

        &.cc-menu__firstLevel  {
            position: static;
            padding-right: rem-calc(70);
        }

        &:after {
            @include media-breakpoint-down(md) {
                content: "";
                background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
                height: 100%;
                position: absolute;
                right: 0;
                top: rem-calc(50);
                width: rem-calc(50);
                height: rem-calc(60);
                padding-top: rem-calc(10);
                padding-bottom: rem-calc(4);
                padding-right: 0;
            }
        }
    }

    &__firstLevel {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: rem-calc(24);
        padding: 0 rem-calc(16);
        position: relative;
        @include media-breakpoint-up(lg) {
            padding: rem-calc(50) rem-calc(24) rem-calc(20);
            width: auto;
            .cc-menu.open & {
                z-index: 12;
            }
        }

        &__item {
            pointer-events: auto;
        }

        &__fakeLink,
        &__link {
            text-wrap: nowrap;
            @include media-breakpoint-down(md) {
                line-height: rem-calc(48);
            }
        }

        &__fakeLink {
            font-size: rem-calc(15);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(0.15);
            color: $black;

            &.selected {
                color: $red;
                cursor: pointer;
            }
        }

        &__title {
            font-size: rem-calc(15);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(0.15);
            line-height: rem-calc(22);

            .cc-menu.open & {
                color: $black-warm;
            }

            &:hover,
            .cc-menu.open .selected & {
                color: $red;
                cursor: pointer;
            }
        }
    }

    &__secondLevel {
        background-color: $white-warm;
        height: 100dvh;
        opacity: 0;
        padding: rem-calc(32) rem-calc(16);
        position: absolute;
        top: unset;
        visibility: hidden;
        width: 100%;
        @include media-breakpoint-down (md) {
            left: 0;
            overflow-x: hidden;
            overflow-y: auto;
            transform: translateX(-100%);
            transition: 300ms;
            z-index: 11;
            height: calc(100dvh - 80px);

            .cc-menu.open & {
                transform: translateX(0%);
                transition: 300ms;
            }

            .selected & {
                opacity: 1;
                transition: 150ms;
                visibility: visible;
            }

            &.overflow-mobile {
                overflow-x: unset;
                overflow-y: unset;
            }
        }
        @include media-breakpoint-up(lg) {
            display: flex;
            flex-direction: row;
            left: 0;
            opacity: 1;
            padding: rem-calc(68) rem-calc(24);
            top: unset;
            transform: translateX(-101%);
            transition: 300ms;
            visibility: visible;
            width: rem-calc(1131);
            z-index: 0;

            .cc-menu.open & {
                transform: translateX(0%);
                transition: 300ms;
                overflow: hidden;
            }

            .selected & {
                opacity: 1;
                transition: 150ms;
                visibility: visible;
                z-index: 11;

                .experience-secondLevelColumnRegion {
                    opacity: 1;
                    visibility: visible;
                    transition: 600ms;
                }
            }

            .experience-secondLevelColumnRegion {
                opacity: 0;
                visibility: hidden;
                transition: 600ms;
            }
        }

        &__sale-icon {
            background-color: $red;
            color: $white;
            display: inline-block;
            font-size: rem-calc(22);
            font-weight: $primary-font-bold;
            height: rem-calc(24);
            line-height: rem-calc(22);
            padding: rem-calc(2);
            position: relative;
            top: rem-calc(2);

            @include media-breakpoint-up (lg) {
                top: rem-calc(4);
            }
        }

        &-arrow-icon {
            display: none;
            opacity: 0;
            transition: 150ms;
            visibility: hidden;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            @include media-breakpoint-down (md) {
                gap: rem-calc(24);
            }
            @include media-breakpoint-up (lg) {
                overflow-y: auto;
                padding-bottom: rem-calc(24);
                &::-webkit-scrollbar {
                    display: none;
                }
            }
            &-inner {
                @include media-breakpoint-up(lg) {
                    display: flex;
                    gap: rem-calc(24);
                }
            }
        }

        &__link {
            &--highlight {
                .cc-menu__secondLevel__title {
                    color: $red;
                }
            }
            &-wrapper {
                align-items: center;
                background-color: $white-warm;
                display: flex;
                gap: rem-calc(10);
                margin-bottom: rem-calc(18);
                z-index: 1;

                @include media-breakpoint-up(lg) {
                    margin-bottom: rem-calc(16);
                }

                &.selected {
                    @include media-breakpoint-up(lg) {
                        .cc-menu__secondLevel__title {
                            color: $red;
                        }
                        .cc-menu__secondLevel-arrow-icon {
                            display: inline-block;
                            opacity: 1;
                            transition: 150ms;
                            visibility: visible;
                        }
                    }
                }

                &.secondary {
                    margin-bottom: rem-calc(8);
                }
            }
        }

        &__title {
            color: $black-warm;
            font-size: rem-calc(26);
            font-weight: $primary-font-semibold;
            line-height: rem-calc(30);

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(24);
                line-height: rem-calc(32);
            }

            &:hover {
                @include media-breakpoint-up(md) {
                    color: $red;
                }
            }

            &.secondary {
                font-size: rem-calc(18);
                font-weight: $primary-font-medium;
                line-height: rem-calc(24);
            }
        }

        &__content {
            @include media-breakpoint-up (lg) {
                width: calc(33.3% - 24px);
                background-color: $white-warm;
                margin-top: auto;
                z-index: 1;
            }
            &-links {
                @include media-breakpoint-down (md) {
                    border-bottom: rem-calc(1) solid $warm-grey-02;
                    border-top: rem-calc(1) solid $warm-grey-02;
                    gap: rem-calc(16);
                    left: rem-calc(-16);
                    margin-bottom: rem-calc(16);
                    padding: rem-calc(16);
                    position: relative;
                    width: 100vw;
                }
                display: flex;
                flex-direction: column;
                gap: rem-calc(12);
                margin-bottom: rem-calc(24);

                &__item-iconWithStoke {
                    stroke: currentColor;
                }
            }

            &-profile {
                display: flex;
                flex-direction: column;
                gap: rem-calc(16);
                @include media-breakpoint-up (lg) {
                    border-top: rem-calc(1) solid $warm-grey-02;
                    gap: rem-calc(12);
                    padding-top: rem-calc(24);
                }
            }

            &-link__item,
            &-profile__item {

                &-icon {
                    height: rem-calc(20);
                    width: rem-calc(20);
                }

                &-text {
                    color: $black-warm;
                    display: flex;
                    font-size: rem-calc(15);
                    font-weight: $primary-font-medium;
                    gap: rem-calc(8);
                    letter-spacing: rem-calc(0.15);
                    line-height: rem-calc(22);

                    &:hover {
                        color: $black-warm;
                    }

                    &:hover {
                        @include media-breakpoint-up(md) {
                            color: $red;
                        }
                    }
                }
            }
        }

        &__tag {
            border-radius: rem-calc(2);
            cursor: pointer;
            font-size: rem-calc(13);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(0.13);
            line-height: rem-calc(18);
            padding: rem-calc(2) rem-calc(6);
            width: auto;

            &.grey {
                color: #625A38;
                background-color:rgba(98, 90, 56, 0.15);
            }

            &.black {
                color: $black;
                background-color: $cold-grey-01;
            }

            &.yellow {
                color: #CEA501;
                background-color: rgba(220, 199, 0, 0.10);
            }

            &.blue {
                color: #1C4597;
                background-color: rgba(28, 69, 151, 0.1);
            }

            &.red {
                color: $red;
                background-color: rgba(230, 0, 0, 0.1);
            }
        }
    }

    &__secondLevelColumn {
        margin-bottom: rem-calc(40);
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: rem-calc(32);
            @include media-breakpoint-up(lg) {
                gap: rem-calc(40);
            }

            @include media-breakpoint-down(md) {
                padding-bottom: rem-calc(40);
            }
        }
        &-title {
            display: block;
            color: $warm-grey-05;
            font-size: rem-calc(12);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(1.2);
            line-height: rem-calc(18);
            margin-bottom: rem-calc(0);
            text-transform: uppercase;

            @include media-breakpoint-up (lg) {
                margin-bottom: rem-calc(16);
            }
        }
    }

    &__secondLevelSecondaryColumn {
        border-top: rem-calc(1) solid $warm-grey-02;
        margin-top: rem-calc(4);
        padding-top: rem-calc(24);
        @include media-breakpoint-up (lg) {
            border-bottom: rem-calc(1) solid $warm-grey-02;
            margin: rem-calc(4) 0 rem-calc(24) 0;
            padding: rem-calc(24) 0;
        }
    }

    &__HighlightsSection {
        &__ctas {
            display: flex;
            gap: rem-calc(8);
            flex-direction: row;
            flex-wrap: nowrap;
            margin-top: rem-calc(16);
            padding: rem-calc(0) rem-calc(16);

            @include media-breakpoint-up (lg) {
                flex-wrap: wrap;
                padding: rem-calc(0);
            }

            &__wrapper {
                @include media-breakpoint-down (md) {
                    overflow-x: auto;
                    margin: rem-calc(0) rem-calc(-16);
                }

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }

        &__cta {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: auto;

            .cc-menu__HighlightsSection__ctas & {
                height: auto;
            }
        }
    }

    &__singleBlockSection {
        &__link {
            display: block;
            height: rem-calc(500);
            margin-top: rem-calc(16);
            position: relative;
            width: 100%;

            @include media-breakpoint-up (lg) {
                height: rem-calc(457);
                width: rem-calc(343);
                overflow: hidden;

                &:hover {
                    .cc-menu__singleBlockSection__image img {
                        transform: scale(1);
                    }

                    .cc-menu__singleBlockSection__overlay {
                        background: rgba(0, 0, 0, 0.40);
                    }
                }
            }
        }

        &__overlay {
            @include animationSlow();
            background: rgba(0, 0, 0, 0.20);
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        &__image {
            display: block;
            height: 100%;
            width: 100%;

            img {
                @include animationSlow();
                height: 100%;
                width: 100%;
                object-fit: cover;

                @include media-breakpoint-up (lg) {
                    transform: scale(1.07);
                }
            }
        }

        &__text {
            bottom: 0;
            color: $white;
            display: flex;
            flex-direction: column;
            gap: rem-calc(8);
            height: 100%;
            justify-content: center;
            position: absolute;
            text-align: center;
            text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.50);
            width: 100%;

            &1,
            &3 {
                font-size: rem-calc(12);
                font-weight: 800;
                letter-spacing: rem-calc(1.2);
                line-height: rem-calc(18);
                text-transform: uppercase;
                z-index: 2;
            }

            &2 {
                font-family: $secondary-font;
                font-size: rem-calc(38);
                font-weight: $secondary-font-bold;
                letter-spacing: rem-calc(0.2);
                line-height: rem-calc(44);
                text-transform: uppercase;
                z-index: 2;
            }
        }
    }

    &__multipleBlockSection {
        display: flex;
        flex-direction: column;
        gap: rem-calc(1);

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    &__blockForMultipleBlocksSection {
        &__link {
            display: block;
            height: 100%;
            position: relative;
            width: 100%;
            padding-top: 100%;

            @include media-breakpoint-up (lg) {
                height: rem-calc(357);
                overflow: hidden;
                width: rem-calc(357);
                padding-top: 0;

                &:hover {
                    .cc-menu__blockForMultipleBlocksSection__image img {
                        transform: scale(1);
                    }

                    .cc-menu__blockForMultipleBlocksSection__overlay {
                        background: rgba(0, 0, 0, 0.40);
                    }
                }
            }
        }

        &__image {
            img {
                @include animationSlow();
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;

                @include media-breakpoint-up (lg) {
                    transform: scale(1.07);
                }
            }
        }

        &__text {
            bottom: 0;
            color: $white;
            display: flex;
            flex-direction: column;
            font-family: $secondary-font;
            font-size: rem-calc(32);
            font-weight: $secondary-font-bold;
            gap: rem-calc(8);
            height: 100%;
            justify-content: center;
            letter-spacing: rem-calc(0.16);
            line-height: rem-calc(49);
            position: absolute;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0px 4.023px 10.059px rgba(0, 0, 0, 0.25);
            width: 100%;
            z-index: 2;
        }

        &__overlay {
            @include animationSlow();
            background: rgba(0, 0, 0, 0.20);
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: 150ms;
            width: 100%;
            z-index: 1;
        }
    }

    &__thirdLevelItemsSection {
        display: flex;
        flex-direction: column;
        gap: rem-calc(16);
        @include media-breakpoint-down (md) {
            &__title {
                margin-bottom: rem-calc(0);
            }
        }
        @include media-breakpoint-up (lg) {
            gap: rem-calc(8);

            &__title {
                margin-bottom: rem-calc(8);
            }
        }
    }

    &__thirdLevel {
        &__title {
            color: $black-warm;
            font-size: rem-calc(18);
            font-weight: $primary-font-medium;
            line-height: rem-calc(24);

            @include media-breakpoint-up (lg) {
                font-size: rem-calc(15);
                &:hover {
                    @include media-breakpoint-up(md) {
                        color: $red;
                    }
                    cursor: pointer;
                }
            }
        }

        &__showAll {
            font-weight: $primary-font-bold;
        }

        @include media-breakpoint-down (md) {
            background-color: $white-warm;
            height: 100dvh;
            left: 0;
            position: absolute;
            top: rem-calc(-48);
            transform: translateX(100%);
            transition: 300ms;
            width: 100dvw;
            z-index: 1;

            .selected + & {
                transform: translateX(0%);
                transition: 300ms;
            }

            &-back {
                border-bottom: rem-calc(1) solid $warm-grey-02;
                display: flex;
                flex-direction: row;
                gap: rem-calc(6);
                padding: rem-calc(12) 0;
                width: 100%;

                &-icon {
                    margin-left: rem-calc(16);
                }

                &-title {
                    color: $warm-grey-05;
                    font-size: rem-calc(13);
                    font-weight: $primary-font-medium;
                    letter-spacing: rem-calc(0.13);
                    line-height: rem-calc(18);
                    margin-right: rem-calc(16);

                    &:hover {
                        color: $red;
                    }
                }
            }

            &__wrapper {
                overflow-y: scroll;
                max-height: 100dvh;
                padding: rem-calc(32) rem-calc(16);
            }
        }
        @include media-breakpoint-up(lg) {
            height: 100%;
            opacity: 0;
            overflow-y: scroll;
            padding-bottom: rem-calc(164);
            position: absolute;
            top: rem-calc(68);
            transition: 150ms;
            visibility: hidden;
            width: 66.6%;

            &::-webkit-scrollbar {
                display: none;
            }

            .cc-menu.open & {
                left: calc(33% + 16px);
            }

            .selected + & ,
            .openFirst + & {
                opacity: 1;
                transition: 150ms;
                visibility: visible;
            }

            .experience-thirdLevelColumnRegion {
                display: flex;
                flex-direction: row;
                gap: rem-calc(12);
                justify-content: space-between;
                padding-right: rem-calc(36);
                text-align: left;
            }
        }
    }

    // Brands Section

    &__brandsSection {
        @include media-breakpoint-up(sm) {
            max-width: rem-calc(337);
        }

        &__title {
            display: block;
            margin-bottom: rem-calc(16);
        }

        .experience-brandsRegion {
            display: flex;
            flex-wrap: wrap;
            gap: rem-calc(1);
        }

        .experience-menu-itemForBrandsSection {
            width: calc(50% - #{rem-calc(1)});
        }

        .cc-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__itemForBrandsSection {
        position: relative;
        height: 100%;

        &__title {
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            font-family: $secondary-font;
            letter-spacing: rem-calc(0.05);
            color: $white;
            font-size: rem-calc(20);
            line-height: rem-calc(25);
            text-transform: uppercase;
        }

        &__textbox {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: rem-calc(30);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;

            &-logo {
                img {
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    filter: drop-shadow(0 0 #{rem-calc(25)} rgba(0, 0, 0, .5));
                }
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.20);
            pointer-events: none;
            transition: all 0.5s ease;
        }

        &:hover {
            .cc-menu__itemForBrandsSection__overlay {
                background: rgba(0, 0, 0, 0.40);
            }
        }
    }

    &__brandCta {
        border-radius: rem-calc(2);
        display: flex;
        flex-direction: row;
        height: rem-calc(42);
        justify-content: space-between;
        padding: rem-calc(8) rem-calc(20);
        width: 100%;

        @include media-breakpoint-up (lg) {
            height: rem-calc(40);
            min-width: rem-calc(325);
        }

        &.Bata {
            background-color: $red1;
        }

        &.WEINBRENNER {
            background-color:#625A38;
            &:hover {
                background-color: $red1;
            }
        }

        &.NORTHSTAR {
            background-color: $black-warm;
            &:hover {
                background-color: $red1;
            }
        }

        &.Bubblegummers {
            background-color: #42AB34;
            &:hover {
                background-color: $red1;
            }
        }

        &.power {
            background-color: #1C4597;
            &:hover {
                background-color: $red1;
            }
        }

        &--logo {
            height: 100%;
            width: 100%;

            img {
                max-height: 100%;
            }
        }
    }
    &__brandsBannerImage {
        &__link {
            display: block;
            height: rem-calc(490);
            margin-top: rem-calc(16);
            position: relative;
            width: 100%;

            @include media-breakpoint-up (lg) {
                width: rem-calc(343);
                overflow: hidden;
            }
        }

        &__image {
            display: block;
            height: rem-calc(400);
            width: 100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &__cta {
            align-items: flex-end;
            border-radius: rem-calc(0) rem-calc(0) rem-calc(2) rem-calc(2);
            bottom: 0;
            display: flex;
            flex-direction: row;
            height: rem-calc(90);
            gap: rem-calc(8);
            justify-content: space-between;
            padding: rem-calc(12);

            &.Bata {
                background-color: $red1;
            }
            &.WEINBRENNER {
                background-color:#625A38;
            }
            &.NORTHSTAR {
                background-color: $black-warm;
            }
            &.Bubblegummers {
                background-color: #42AB34;
            }
            &.power {
                background-color: #1C4597;
            }
        }

        &__text__wrapper {
            display: flex;
            flex-direction: column;
            gap: rem-calc(10);
        }

        &__text {
            color: $warm-grey-03;
            font-size: rem-calc(15);
            font-weight: $primary-font-medium;
            letter-spacing: rem-calc(0.15);
            line-height: rem-calc(22);
        }

        &__logo {
            height: 100%;
            width: 100%;

            img {
                max-height: 100%;
            }
        }

        &--arrow,
        &__arrow {
            height: rem-calc(24);
            width: rem-calc(24);
        }
    }

    &__brandCta {
        border-radius: rem-calc(2);
        display: flex;
        flex-direction: row;
        height: rem-calc(42);
        justify-content: space-between;
        padding: rem-calc(8) rem-calc(20);
        width: 100%;

        @include media-breakpoint-up (lg) {
            height: rem-calc(40);
            min-width: rem-calc(325);
        }

        &.Bata {
            background-color: $red1;
        }

        &.WEINBRENNER {
            background-color:#625A38;
            &:hover {
                background-color: $red1;
            }
        }

        &.NORTHSTAR {
            background-color: $black-warm;
            &:hover {
                background-color: $red1;
            }
        }

        &.Bubblegummers {
            background-color: #42AB34;
            &:hover {
                background-color: $red1;
            }
        }

        &.power {
            background-color: #1C4597;
            &:hover {
                background-color: $red1;
            }
        }

        &__logo {
            height: 100%;
            width: 100%;
        }
    }

    // Products Section

    &__productsSection {
        @include media-breakpoint-up(sm) {
            max-width: rem-calc(342);
        }

        &__title {
            display: block;
            margin-bottom: rem-calc(16);
        }

        .cc-recommended-slider {
            padding: 0;
        }

        .experience-productsRegion {
            display: flex;
            flex-wrap: wrap;
            column-gap: rem-calc(1);
            row-gap: rem-calc(16);
        }

        .experience-commerce_carousel_product-carouselProductTile {
            width: calc(50% - #{rem-calc(1)});

            &:not(:has(*)) {
                display: none;
            }
        }

        .cc-carousel-tile {
            .tile-body {
                padding: rem-calc(12) rem-calc(8);

                @include media-breakpoint-down(xs) {
                    padding: rem-calc(12);
                }

                .cc-sales-reduced {
                    .cc-price {
                        color: $red1;
                    }
                }

                .cc-price-reduced,
                .cc-tile-colors {
                    display: none;
                }

                @include original-and-pp-price-container(11, 9, 18, 6, flex-start);

                .cc-original-price-container,
                .cc-pp-price-container {
                    @include media-breakpoint-down(xs) {
                        line-height: rem-calc(12);
                    }

                    .cc-original-price-percentage,
                    .cc-pp-price-percentage {
                        font-weight: $primary-font-regular;
                        color: $red1;
                        margin-left: rem-calc(3);
                    }
                }
            }

            &:hover {
                .cc-tile-product-name,
                .cc-price {
                    @include media-breakpoint-up(lg) {
                        color: $red1;
                    }
                }
            }
        }

        .cc-product-label {
            display: none;
        }
    }

    &__promotionBlockSection {
        padding-bottom: rem-calc(40);

        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        &__eyelet {
            display: block;
            margin-bottom: rem-calc(16);
        }

        &__link {
            display: block;
            margin: 0 rem-calc(-16);
            border-radius: rem-calc(2);
            overflow: hidden;
            position: relative;

            @include media-breakpoint-up(lg) {
                margin: 0;

                &:hover {
                    .cc-menu__promotionBlockSection__info {
                        color: $primary-color;
                    }
                    .cc-menu__promotionBlockSection__image img {
                        transform: scale(1);
                    }
                    .cc-menu__promotionBlockSection__overlay {
                        background: rgba(0, 0, 0, 0.40);
                    }
                }
            }
        }

        &__overlay {
            @include animationSlow();
            background: rgba(0, 0, 0, 0.20);
            height: 100%;
            position: absolute;
            width: 100%;
            z-index: 1;
        }

        &__image {
            display: block;
            aspect-ratio: 375 / 188;

            img {
                @include animationSlow();
                height: 100%;
                width: 100%;
                object-fit: cover;

                @include media-breakpoint-up (lg) {
                    transform: scale(1.07);
                }
            }
        }

        &__info {
            @include animationSlow();
            background: $warm-grey-01;
            padding: rem-calc(16) rem-calc(48) rem-calc(16) rem-calc(16);
            position: relative;
            color: $black;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                padding: rem-calc(12) rem-calc(44) rem-calc(12) rem-calc(12);
            }
        }

        &__title {
            display: block;
            font-family: $secondary-font;
            font-weight: $secondary-font-bold;
            font-size: rem-calc(28);
            line-height: rem-calc(36);
            letter-spacing: rem-calc(0.14);
            text-transform: uppercase;
            margin: 0;
            color: inherit;

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(32);
                line-height: rem-calc(40);
                letter-spacing: rem-calc(0.16);
            }
        }

        &__subtitle {
            display: block;
            font-weight: $primary-font-medium;
            font-size: rem-calc(15);
            line-height: rem-calc(22);
            letter-spacing: rem-calc(0.15);
            margin: rem-calc(12) 0 0 0;
            color: inherit;
        }

        &__text {
            display: block;
            font-weight: $primary-font-regular;
            font-size: rem-calc(13);
            line-height: rem-calc(18);
            letter-spacing: rem-calc(0.13);
            margin: rem-calc(4) 0 0 0;
            color: inherit;
        }

        &__icon {
            display: block;
            width: rem-calc(20);
            height: rem-calc(20);
            position: absolute;
            bottom: rem-calc(18);
            right: rem-calc(18);

            @include media-breakpoint-up(lg) {
                bottom: rem-calc(14);
                right: rem-calc(14);
            }
        }
    }
}
